
.visual_warpper{position:relative;display:inline-block;width:92%;height:930px;}
.inner{
position: absolute;
top:310px;left: 150px;
color: #fff;
font-weight: 600;
}
/*기존*/
.inner .t01{
opacity:0;transform: 
translate3d(0, 120px, 0);  
transition:0.8s all ease-in-out;color:#fff;
font-size:35px;
}

.inner .t02{
opacity:0;
transform: translate3d(0, 120px, 0); 
transition:1s all ease-in-out;
color:#fff;
font-size:50px;
margin-top:15px;
line-height:1.5;
}

.swiper_dim{
width:100%;
height:100%;
background: #11111124;
position:absolute;
top:0;
z-index:2;
}

section{width:92%;float:left;background:#fff;position:relative;display:inline-block;z-index:2;margin-top:-4px;
    padding-top:300px;padding-bottom:300px;}
    
    

/*section_04*/
.brand_wrapper{margin:0 auto;width:100%;position:relative;}
.brand_inner{position:relative;display:inline-block;}
.brand_img img{width:100%;}
.brand_logo{position:absolute;bottom:0;padding:5%;}
.brand_line{position:absolute;top:25px;right:25px;cursor:pointer;}
.brand_line_hover{ transform: translate3d(-35px, 0, 0);  transition:0.8s all ease-in-out;;z-index:3;}
.brand_hover_txt{position:absolute;bottom:20%;left:5%;opacity:0;transform: translate3d(0, 0, 0);cursor:default;}
.brand_dim{width:100%;height:100%;background:#111;position:absolute;top:0;opacity:0.3;display:none;cursor:pointer;}
.dim_slide_up{opacity:1;transform: translate3d(0, -40px, 0);  transition:0.8s all ease-in-out;
color:#fff;}
.col-lg-6{margin:0!important;padding:0!important;}

/*section_05*/
#office_wrapper{width:100%;padding-left:15%;overflow:hidden;display:block;--swiper-theme-color: #ffffff;padding-bottom:70px;}

#office_wrapper img{width:100%;}
.button_slide .goods_wrapper{margin:0;padding:0;}
.swiper-scrollbar {left: 10px !important;bottom: 0px !important;height: 1px !important;width: calc(100% - 20px) !important;}
.swiper-scrollbar {border-radius: 10px;position: relative;background: rgba(0,0,0,.1);}
.swiper-container-horizontal>.swiper-scrollbar {position: absolute;left: 1%;bottom: 3px;z-index: 50;height: 5px; width:100%}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill{background:#000!important;}
.tit{font-size: 15px;font-weight: bold; color: #000; margin-bottom: 20px;}


