#service_wrapper img{
    width:100%;
}
#service_wrapper{
    /*max-width:1050px;*/
    width:60%;
    margin:0 auto;
}
.service_inner{
    width:100%;
    height:100%;
    margin:0 auto;
}

.service_main_wrapper{
    font-size:30px;font-weight:600;
}

.service_txt{
    margin-top:40px;
    line-height:1.8;
}

.graph{margin-top:80px;
position:relative;
display:inline-block;
width:100%;
}

.graph_name{
    font-weight:600;
    font-size:30px;
}
#myChart{
    cursor:pointer;
}
/* CSS */
.chart-container canvas {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}


#grapy_warpp div{width:45%;float:left;}
.contentText{float:right;display:inline}


.chart-title{
    font-size:20px;
    font-weight:600;
    width:100%;
    padding-bottom:10px;
    display:inline-block;
    text-align:center;
}
.section_greep{
    background:#ddd;
}

.chart-content{
    margin-top:10px;
    font-size:14px;
    font-weight:500;
}

.chart-container{
    width:48%;float:left;
}
.chart-container:nth-child(2){
    margin-left:2%;
}

.hito{
    display:inline-block;
    width:100%;
    margin-top:80px;
    float:left;
}

.grapy_s{
    width:55%;float:left;
}
.chart-content2 {
    margin-top:150px;
    text-align: left; 
    align-items: center;
    width:40%;
    float:left;
    padding-left:20px;
    font-weight:500;
}

#videArea{
    margin-top:80px;
}
#videArea ul{
    position:relative;
    display:inline-block;
    width:100%;
}

.video_service_title {
    position:relative;
    display:inline-block;
    width:100%;
}
.video_service_title div{
    float:left;
    width:48%;
    text-align:center;
    font-weight:600;
    margin-top:4%;
    padding-bottom:1%;
    font-size:18px;
    font-weight:500!important;
}
#videArea ul li{
    font-size:25px;
    text-align:center;
    color:#fff;
    width:48%;
    float:left;
    height:300px;
    background:#333;
    border:1px solid #333;  
}

#videArea ul li video{
    width:100%;
    height:100%;
}

#videArea ul li:nth-child(2){
    margin-left:2%;
}

.text-content{
    width:100%;
    margin-top:2%;
    text-align:center;
    font-weight:500;
    text-align:left!important;
}


#biz{
    width:100%;
    position:relative;
    display:inline-block;
    margin-top:80px;
}


.bizImg{
    position:relative;
    display:inline-block;
    width:100%;
}
.bizImg ul{margin-top:4%;}
.bizImg ul li{
    float:left;
    width:32%;
    margin-left:1%;
}
.bizImg ul li span{font-weight:600;
position:relative;display:inline-block;
margin-top:10px;
}



.biz_ul ul{width:60%;margin:0 auto;text-align:center;
margin-top:30px;
}
.biz_ul ul li{
    float:left;
    height:30px;
    font-weight:500;
    font-size:16px;
    text-align:center;
    margin-left:3px;
}
.biz_ul ul li:nth-child(1){margin-left:0;}

#biz_info{
    width:100%;
    position:relative;
    display:inline-block;
    margin-top:80px;
}
.bizImg2{margin-top:40px;}

#brand_save{
    width:100%;
    position:relative;
    display:inline-block;
    margin-top:80px;
}


.brand_title{
    margin-top:50px;
    font-weight:600;
    font-size:40px;
}
.branc_line_s ul{
position:relative;
display:inline-block;
width:100%;
margin-top:70px;

}
.branc_line_s ul li {
    float: left;
    border: 1px solid #111;
    font-weight: 400;
    width: 18.9%;
    height: 80px;
    margin-top: 10px;
    margin: 0 auto;
    margin-top: 10;
    padding-top: 12px;
}
.text-center{text-align:left!important;}
.text-center span {
    font-weight: 600;
    position: relative;
    margin-top: 40px;
    font-size: 81px;
    display: inline-block;
    position: absolute;
    margin-top: -35px !important;
    margin-left: 36px;
}
.branc_line_s{
    text-align:center;
}


.branc_line_s span{
    font-weight:800!important;;
}
.branc_line_s{
    width:100%;
    position:relative;
    display:inline-block;
}



.branc_line_s ul li:nth-child(1){
 
}
.branc_line_s ul li:nth-child(2){
 margin-left:5px;
}

.branc_line_s ul li:nth-child(3){
    margin-left:5px;
}

.branc_line_s ul li:nth-child(4){
    margin-left:5px;
}

.branc_line_s ul li:nth-child(5){
    margin-left:5px;
}



.brand_footer{

}
.footer_list{
    width:100%;
    margin-top:50px;
    text-align:left;
    font-weight:500;
}

.link_list_01{
    margin-top:30px;
    color:#00bdd4;
    cursor:pointer;
}


.service_main_wrapper  p{display:inline;}


@media screen and (max-width:1900px) and (min-width: 1100px) {

    .p_l_d_b2{padding-top:100px!important;margin-top:-5px!important}

    }

    
    @media screen and (max-width:1100px) and (min-width:600px) {

    .p_l_d_b2{padding-top:80px!important;margin-top:-5px!important}
    #service_wrapper{width:90%}
    .chart-container{width:100%;padding-bottom:40px;}
    .chart-content{margin-top:40px;font-size:17px;line-height:1.5;}
    .chart-content2{margin-top:40px;width:100%;font-size:17px;line-height:1.5;}

    .grapy_s{width:100%;}
    .text-content{font-size:17px;text-align:left;}

    .biz_ul ul{width:100%;}
    .biz_ul ul li{margin-left:0px;font-size:17px;}
    .service_main_wrapper{font-size:25px;}

    /*빈백 소파 부분*/


    .service_main_wrapper{font-size:22px;}
    .text-center span {
        font-weight: 600;
        position: relative;
        margin-top: 40px;
        font-size: 63px;
        display: inline-block;
        position: absolute;
        margin-top: -35px !important;
        margin-left: 27px;
    }

    }
    
    @media screen and (max-width:800px) and (min-width:600px) {
    
  
        .text-center span {
            font-weight: 600;
            position: relative;
            margin-top: 40px;
            font-size: 42px;
            display: inline-block;
            position: absolute;
            margin-top: -19px !important;
            margin-left: 10px;
        }
        .branc_line_s ul li{border:none;}

    }

    @media screen and (max-width:600px) and (min-width:200px) {


        .p_l_d_b2{padding-top:80px!important;margin-top:-5px!important}
        #service_wrapper{width:90%}
        .chart-container{width:100%;padding-bottom:40px;}
        .chart-content{margin-top:40px;font-size:14px;line-height:1.5;}
        .chart-content2{margin-top:40px;width:100%;font-size:14px;line-height:1.5;}
        .biz_ul{width:100%;}
        .grapy_s{width:100%;}
        .text-content{font-size:14px;margin-top:5%;text-align:left;}
    
        .biz_ul ul{width:100%;}
        .biz_ul ul li{margin-left:0px;font-size:14px;}
        .text-content{text-align:left;}
        #videArea ul li{height:150px;}
        .service_main_wrapper{font-size:20px;}
        .service_txt{margin-top:20px;}
        .hito{margin-top:40px;}

        .text-center{
            font-size:16px;

        }
      
        .text-center span {
            font-size: 37px;
        }
        .branc_line_s ul {width:100%;}
        .branc_line_s ul li{border:none;
        width:48%;float:left;margin:0;
        }

    }


        
    @media screen and (max-width:500px) and (min-width:300px) {


        .service_main_wrapper .text-center{text-align:center!important;}
        .branc_line_s ul {
            margin-top: 80px;
        }
        .branc_line_s ul li {
            /* padding: 5px; */
            font-size: 15px;
        }

        .branc_line_s ul li {
            float: left;
            font-weight: 400;
        }

        .branc_line_s ul li{
            margin:0px!important;
            border:none;
        
        }
        .text-center p{
            position:relative;
            display:inline-block;
            width:100%;
            text-align:center;
            font-size:15px!important;
            font-weight:600;
        }
        .text-center span {
            margin:0!important;
            padding:0!important;
            text-align:center;
            width:100%;
            position:relative;
            display:inline-block;
            margin-top:20px!important;
        }
            .video_service_title div{
                margin-top:20px;
                font-size:14px;
                font-weight:550;
            }
            .branc_line_s ul{
                margin-top:20px;
            }
    
        }
      