
section{padding-bottom:0!important;}
#news{width: 92%;float:left;position:relative;z-index: 2;background: #fff;}


.partners{width:100%;float:right;font-size:2px;padding-bottom:80px;font-size:25px;font-weight:bold;text-align:center;
position:relative;display:inline-block;}
.border_img{width:100%;position:relative;display:inline-block;border:2px solid #58b5ca;}
.board_cont{width:100%;font-size: 16px;line-height: 1.8; display:block;
display:-webkit-box; -webkit-line-clamp:5;-webkit-box-orient:vertical;overflow:hidden;margin-top:15px;}
.border_img_02{float:left;width:25%;position:relative;display:inline-block;border:2px solid #58b5ca;}
.board_cont_02{float:left;width:70%;font-size: 16px;line-height: 1.8; display:block;margin-left:4%;
display:-webkit-box; -webkit-line-clamp:5;-webkit-box-orient:vertical;overflow:hidden;margin-top:10px;}
.board_cont_02 ul li{float:left;width:100%;}
.board_cont_02 ul li:nth-child(1){color: #58b5ca;font-weight: 600;font-size: 20px; }
.board_cont_02 ul li:nth-child(2){font-size: 16px;margin-top: 10px;text-align: justify;}


.board_cont_03{display:-webkit-box; -webkit-line-clamp:5;-webkit-box-orient:vertical;overflow:hidden;margin-top:20px;}
.board_cont_03 ul li{float:left;width:100%;}
.board_cont_03 ul li:nth-child(1){color: #58b5ca;font-weight: 600;font-size: 20px;text-align:center; }
.board_cont_03 ul li:nth-child(2){font-size: 16px;margin-top: 10px;text-align:center; }

/*중간 배너*/
.middle_visual{width:100%;margin-top:100px;}

.partner_wrapper{width:68%;margin:0 auto;margin-top:50px;}
.partners_title dt{font-size:30px;padding-bottom:5px;}
.partners_title dd{padding-bottom:40px;font-size:20px;}
#maill_frame{width:65%;height:1000px;margin:0 auto;margin-top:100px;}
#maill_frame iframe{width:100%;height:100%;}
#news img{width:95%;}

@media screen and (max-width:800px) and (min-width:300px) {
.border_img_02{margin-top:10px;}
.partner_wrapper{width:95%;}
.border_img{margin-top:20px;}
#maill_frame{width:100%;}
.six_img{width:60%;margin:0 auto;}
.six_img div{margin-top:40px;}
#news{width:100%}
}