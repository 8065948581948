

.careers_visual_01{width:100%;position:relative;display:inline-block;}
.careers_visual_01_img{width:100%;}
.careers_visual_01_m_img{width:100%;display:none;}

.careers_visual_02{width:100%;position:relative;display:inline-block;}
.careers_visual_02_img{width:100%;}
.careers_visual_02_m_img{width:100%;display:none;}

.careers_visual_03{width:100%;position:relative;display:inline-block;}
.careers_visual_03_img{width:100%;}
.careers_visual_03_m_img{width:100%;display:none;}

.careers_visual_04{width:100%;position:relative;display:inline-block;}
.careers_visual_04_img{width:100%;}
.careers_visual_04_m_img{width:100%;display:none;}

.careers_visual_05{width:100%;position:relative;display:inline-block;}
.careers_visual_05_img{width:100%;}
.careers_visual_05_m_img{width:100%;display:none;}






.careers_select{width:50%;margin:0 auto;padding-top:100px;text-align:center}
.careers_section_title{width:70%;margin:0 auto;font-size:25px;font-weight:600;cursor:default;padding-bottom:50px;}
.careers_select ul li{float:left;width:33%;font-size:25px;font-weight:600;cursor:pointer;}
.seciton_lifestyle{
    width:100%;
    margin:0 auto;
}
.section_life_style_left img{
    width:100%;
}
.section_life_style_left{
    float:left;
    width:56%;
}
.section_life_style_right{
    float:left;
    width: 41%;
    font-weight:500;
    font-size:17px;
    line-height:1.7;
    margin-top:190px;
    word-break: keep-all;

}
.careers_search{
    width:100%;
    position:relative;
    display:inline-block;

}
.careers_search_inner{
    width:70%;
    margin:0 auto;
    margin-top:40px;
}

.careers_search_title{
    font-size:45px;
    font-weight:600;
}
.careers_search_sub{
    margin-top:40px;
}
.careers_search_sub dt{
    font-size:30px;
    font-weight:600;
}
.careers_search_sub dd{
    font-size:17px;
    margin-top:5px;
    font-weight:500;
}
.careers_search_img{margin-top:100px}
.careers_search_img ul li{float:left;width:48%;}
.careers_search_img ul li img{width:100%;}
.careers_search_img ul li:nth-child(2){margin-left:2%;}

/*복리 후생 부분*/
.welfare_wrapper {
    width: 75%;
    margin: 0 auto;
    margin-left: 18%;
}
.welfare_wrapper ul{margin-top:5px;}
.welfare_wrapper ul li{float:left;width:48%;}
.welfare_wrapper .secont_w{margin-left:2%;}
.welfare_wrapper ul li dl ul li{width:100%;font-weight:600;line-height:1.8;word-break:normal;font-size:17px;}
.welfare_wrapper ul li dl dt{font-size:30px;}
.welfare_wrapper ul li dl dd{margin-left:50px;}
.welfare_wrapper ul li dl dt img{width:48px;display:block;float:left;}
.welfare_wrapper ul{position:relative;display:inline-block;width:100%;height:100%;;padding-bottom:20px;}
.welfare_wrapper ul li dl ul li span{font-weight:500;display:inline-block;margin-left:10px;}
.welfare_wrapper ul li dl ul li .no_span{margin-left:0px;}


/*채용정보 관련 css*/
.section_01_inner{}
.employment_wrapper{
    width: 70%;
    margin: 0 auto;
    padding-bottom:150px;
}
.employment_title{
    font-size:25px;
    font-weight:600;
}
.emplyment_sub{
    font-size:22px;
    font-weight:600;
    padding-top:10px;
    padding-bottom:10px;
}
.emplyment_pr{
    font-size:17px;
    font-weight:500;
}
.employment_number dt{
    font-size:22px;
    font-weight:600;
    padding-top:30px;
    padding-bottom:20px;
}
.employment_number dd{
    font-size:17px;
    font-weight:500;

}

.emplyment_icon{padding-top:40px;}
.e_icon_first{float:left;width:66%}

.e_icon_first li dt{
    text-align:center;
}
.e_icon_first li dd{
    text-align:center;
    font-weight:500;
    font-size:17px;
    padding-top:10px;
}
.e_icon_first dt{}
.e_icon_first img{width:30%;}

.e_icon_second{float:left;width:22%;position:relative;}
.e_iocn_bar{position:absolute;top:31%;left:-25px;}
.e_icon_second li{
    float:left;
    border:1px solid #cdcdcd;
    width:100%;
    height:200px;
    padding-top:50px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-left-radius:50%;
    border-bottom-right-radius:50%;
}
.e_icon_second li dt{
    text-align:center;
}
.e_icon_second li dd{
    text-align:center;
    font-weight:500;
    font-size:17px;
    padding-top:10px;
}
.e_icon_text{
    width:100%;
    display:inline-block;
    text-align:center;
    padding-top:25px;
    font-weight:500;
}

.e_info{
    padding-top:40px;
    position:relative;
    display:inline-block;
    font-size:25px;
    font-weight:600;
    width:100%;
    padding-bottom:10px;
}
.e_info_sub{
    font-weight:500;
    font-size:17px;
    padding-top:10px;
}
.e_info_button{
    margin-top:40px;
    width:180px;
    height:50px;
    line-height:40px;
    border:4px solid #cdcdcd;
    padding-left:10px;
    font-size:25px;font-weight:700;
    cursor:pointer;
}
/*map*/
.map_wrapper{width:70%;margin:0 auto;}
.map_section{padding-bottom:20px;}
.map_title{font-size:25px;font-weight:700;padding-bottom:5px;}
.map_add{font-size:17px;font-weight:500;padding-bottom:5px;}
.map_tel{font-size:17px;padding-bottom:5px;}
.map_inner{padding-bottom:50px;height:350px;}

/**/

.e_icon_first{width:100%;}
.e_icon_first li{height:auto;border-radius:1px;width:31%;margin-left:1%;
float:left;border:1px solid #cdcdcd;height:180px;}
.e_icon_first li dt{margin-top:30px}
.e_iocn_bar{display:none;}
.e_icon_second{width:95%;margin-top:40px;}
.e_icon_second li{border-radius:1px;}


/*지원하기 부분*/
.title{
    width:55%;
    margin:0 auto;
    font-size: 4.9rem;
    font-weight: bold;
    margin-top: -70px;
}
.choice_iframe {width:75%;height:1600px;margin:0 auto;}
.choice_iframe iframe{width:100%;height:100%;}



/*TAB 부분 제이쿼리 추가작업*/


.careers_select ul .current{color:#00bdd4;}
.tabcontent {display:none;}
.tabcontent.current {display:block;}

.tabcontent2 {display:none;}
.tabcontent2.current2 {display:block;}

/*반응형 채용안내 아이콘 부분*/
.map_store_info{width:70%;margin:auto;height:80px;cursor:pointer;}
.careers_section_title img{cursor:pointer;}


.e_info_sub ul li:hover{color:#00bdd4;cursor:pointer}
.e_info_sub ul li{float:left;border:1px solid #cdcdcd;padding:15px;}
.e_info_sub ul li:nth-child(1){}
.e_info_sub ul li:nth-child(2){margin-left:1%;}
.e_info_sub ul li:nth-child(3){margin-left:1%;}


/*반응형*/
@media screen and (max-width:1600px) and (min-width: 1400px) {
    .section_life_style_right{margin-top:0;}
    .careers_select{width:65%!important;}
}

@media screen and (max-width:1400px) and (min-width: 1200px) {
    .section_life_style_right{margin-top:120px;}
    .careers_select{width:70%!important;}
}

@media screen and (max-width:1200px) and (min-width: 1000px) {
    .section_life_style_right{margin-top:80px;}
    .careers_select{width:70%!important;}
    /*복지부분*/
    .welfare_wrapper{margin:0 auto}
    
    .e_icon_first li{height:auto;border-radius:1px;width:31%;margin-left:1%;
        float:left;border:1px solid #cdcdcd;height:150px;}
        .e_icon_first li dt{margin-top:30px}

}
@media screen and (max-width:1000px) and (min-width: 800px) {
    .section_life_style_left{float:none;width:100%;}
    .section_life_style_right{float:none;width:85%;margin:0 auto;font-size:20px;margin-top:80px;}
    .careers_search_inner{width:85%;margin-top:80px;}
    .careers_select{width:80%!important;padding-top:60px;}
    /*복지부분*/
    .welfare_wrapper{margin:0 auto;width:90%;}
    .employment_wrapper{margin:0 auto;width:90%;}
    /*지원하기부분*/
    .title{width:75%; margin:0 auto;font-size: 4.9rem;font-weight: bold;margin-top: -70px;}
    .choice_iframe {width:75%;height:1800px;margin:0 auto;}


}

@media screen and (max-width:800px) and (min-width: 500px) {
    .section_life_style_left{float:none;width:100%;}
    .section_life_style_right{float:none;width:85%;margin:0 auto;font-size:20px;margin-top:80px;}
    .careers_search_inner{width:85%;margin-top:80px;}
    .careers_select{width:80%!important;margin-top:50px;}
      /*복지부분*/
      .welfare_wrapper{margin:0 auto;width:90%;}  
      .employment_wrapper{margin:0 auto;width:90%;}
      .e_icon_first li dd{font-size:15px;}
    /*지원하기부분*/
    .title{width:75%; margin:0 auto;font-size: 4.9rem;font-weight: bold;margin-top: -70px;}
    .choice_iframe {width:75%;height:1800px;margin:0 auto;}

}



@media screen and (max-width:700px) and (min-width: 300px) {
      /*복지부분*/
      .welfare_wrapper{width:90%;margin:0 auto;}  
      .welfare_wrapper ul li{width:100%;}
      .welfare_wrapper ul{margin:0;padding:0;}
      .welfare_wrapper ul li:nth-child(1){padding-bottom:20px;}
      .welfare_wrapper ul li span{margin-top:5px}
      .employment_wrapper{margin:0 auto;width:90%;}
      .e_icon_first li dd{font-size:15px;}
      .map_wrapper{margin:0 auto;width:90%;}
      .map_inner{padding-bottom:50px;height:250px;}
    /*지원하기부분*/
    .title{width:90%; margin:0 auto;font-size:3rem;font-weight: bold;margin-top: -50px;}
    .choice_iframe {width:95%;height:1800px;margin:0 auto;}
    .store {width:100%!important;}
    
}


@media screen and (max-width:800px) and (min-width: 300px) {
    .section_life_style_left{float:none;width:100%;}
    .section_life_style_right{float:none;width:94%;margin:0 auto;font-size:15px;margin-top:60px;}
    .careers_search_inner{width:90%;margin-top:60px;}
    .careers_select{width:100%!important;margin-top:0px;padding-top:60px;padding-bottom:60px;}
    .careers_select ul li{font-size:18px;}
    .careers_section_title{padding-bottom:20px;font-size:18px;text-align:center;}
    .employment_wrapper{margin:0 auto;width:90%;}

    .e_icon_first li{height:auto;border-radius:1px;width:31%;margin-left:1%;
        float:left;border:1px solid #cdcdcd;height:150px;}
        .e_icon_first li dt{margin-top:30px}

}

@media screen and (max-width:400px){
    .e_icon_first li dt img{width:50%;}
}

/*이미지 배경 반응형*/
@media screen and (max-width:800px) and (min-width:200px) {
    .tabcontent2.current2{margin-top:60px;}
}
@media screen and (max-width:1000px) and (min-width:200px) {
    .careers_visual_01_img{display:none;}
    .careers_visual_01_m_img{display:block;}
    .careers_visual_02_img{display:none;}
    .careers_visual_02_m_img{display:block;}
    .careers_visual_03_img{display:none;}
    .careers_visual_03_m_img{display:block;} 
    .careers_visual_04_img{display:none;}
    .careers_visual_04_m_img{display:block;} 
    .careers_visual_05_img{display:none;}
    .careers_visual_05_m_img{display:block;} 
}

@media screen and (max-width:900px) and (min-width:700px) {

    .map_store_info{width:70%;margin:0 auto;padding-bottom:50px;}
}
    


@media screen and (max-width:700px) and (min-width:200px) {

.map_inner{overflow:hidden;position:relative;display:inline-block;width:100%;}
.map_wrapper{margin-top:30px;}
.map_store_info{width:88%;margin:0 auto;padding-bottom:50px;}
/*입사지원 양식 변경 작업*/
.e_info_sub ul{padding-bottom:100px;}
.e_info_sub ul li{float:left;border:1px solid #cdcdcd;padding:15px;width:100%;}
.e_info_sub ul li:nth-child(2){margin:0;margin-top:1%;}
.e_info_sub ul li:nth-child(3){margin:0;margin-top:1%;}
}






/*지도 MAP 추가 작업*/
#map{width:100%;height:350px;}
.customoverlay {position:relative;bottom:85px;border-radius:6px;border: 1px solid #ccc;border-bottom:2px solid #ddd;float:left;}
.customoverlay:nth-of-type(n) {border:0; box-shadow:0px 1px 2px #888;}
.customoverlay a {display:block;text-decoration:none;color:#000;text-align:center;border-radius:6px;font-size:14px;font-weight:bold;overflow:hidden;background: #00c7e3;background: #00c7e3 url(https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/arrow_white.png) no-repeat right 14px center;}
.customoverlay .title {display:block;text-align:center;background:#fff;margin-right:35px;padding:10px 15px;font-size:14px;font-weight:bold;}
.customoverlay:after {content:'';position:absolute;margin-left:-12px;left:50%;bottom:-12px;width:22px;height:12px;background:url('https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png')}